import { constants } from '@wsb/guac-widget-core';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../common/constants/headerTreatments';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL } =
  constants.paintJobs;

const id = 'layout17';
const imageTreatments = {
  [FILL]: 'category-overlay',
  [FIT]: 'category-overlay',
  [INSET]: 'category-solid',
  [BLUR]: 'category-overlay',
  [LEGACY_BLUR]: 'category-overlay'
};

export default {
  id,
  name: 'dusk',
  packs: {
    color: '#7C4889',
    font: 'cabin'
  },
  logo: {
    font: 'primary'
  },
  packCategories: {
    color: colorPackCategories.ACCENT
  },
  headerProperties: {
    alignmentOption: 'left'
  },
  headerTreatmentsConfig: {
    defaultHeaderTreatment: FILL,
    heroContentItems: ['tagline', 'tagline2', 'cta'],
    imageTreatments
  },
  hasNavWithBackground: true,
  showSlideshowTab: true,
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob: DARK,
  buttons: {
    primary: {
      fill: buttons.fills.SOLID,
      shape: buttons.shapes.SQUARE,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    secondary: {
      fill: buttons.fills.SOLID,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    ...COMMON_BUTTON_CONFIG
  }
};
