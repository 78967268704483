import * as utils from '../../../common/Themes/Default/Dials/Colors/utils';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import Default from '../../../common/Themes/Default/Theme';
import { constants } from '@wsb/guac-widget-core';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import * as boldIcons from '../../../common/IconPacks/boldOutline';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Cascade } from '../../../common/loaders';
import { levelFilter } from '../../../common/utils/text';
import { mergeTypographyOverrides } from '../../../common/utils/typography';
import { merge } from 'lodash';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../../common/constants/headerTreatments';
import themeConfig from '../themeConfig';

// The Minimal theme left aligns content heading and text.  Add a widget layout to this array for center alignment:
const widgetsWithCenteredText = ['about1', 'content4', 'introduction4', 'payment1', 'payment2'];

const { FULL_UNDERLINE } = sectionHrTypes;
const { buttons } = constants;
const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highContrast',
    fontSize: 'large',
    fontWeight: 'normal',
    letterSpacing: 'normal'
  }
};

class Theme17 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme17';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        hasLogoAlign: true,
        showOverlayOpacityControls: true,
        hasNavBackgroundToggle: true,
        useSlideshow: true,
        headerTreatmentsConfig: {
          ...defaultProps.headerTreatmentsConfig,
          heroContentItems: ['tagline', 'tagline2', 'cta'],
          imageTreatments: {
            [FILL]: 'category-overlay',
            [FIT]: 'category-overlay',
            [INSET]: 'category-solid',
            [BLUR]: 'category-overlay',
            [LEGACY_BLUR]: 'category-overlay'
          }
        }
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...((widgetType === 'HEADER' || !widgetsWithCenteredText.includes(widgetPreset)) && {
        alignmentOption: 'left'
      })
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      backgroundColorNavSolid() {
        const background = utils.getDial(this, 'background');
        return background.setAlpha(25);
      },
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal'
          }
        },
        HeadingBeta: {
          ...typographyShared1
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingEpsilon: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'none'
          }
        },
        NavAlpha: {
          style: {
            'font': 'alternate',
            'color': 'highContrast',
            'fontSize': 'medium',
            'fontWeight': 'normal',
            'letterSpacing': '0.063em',
            'textTransform': 'none',
            ':hover': {
              color: 'highContrast'
            },
            ':active': {
              color: 'highContrast'
            }
          },
          active: {
            style: {
              'fontWeight': 'normal',
              'color': 'highContrast',
              'paddingBottom': 'xxsmall',
              'borderBottom': '1px solid',
              ':hover': {
                color: 'highContrast'
              }
            }
          }
        },
        SubNavAlpha: {
          style: {
            'font': 'alternate',
            'color': 'section',
            'fontSize': 'medium',
            'fontWeight': 'normal',
            'letterSpacing': '0.063em',
            'textTransform': 'none',
            ':hover': {
              color: 'section'
            },
            ':active': {
              color: 'section'
            }
          },
          active: {
            style: {
              'fontWeight': 'normal',
              'color': 'section',
              'paddingBottom': 'xxsmall',
              'borderBottom': '1px solid',
              ':hover': {
                color: 'section'
              }
            }
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: props => {
          const { size = 'default' } = props;
          const sizes = {
            small: {
              fontSize: 'small'
            },
            default: {
              fontSize: 'medium'
            },
            large: {
              fontSize: 'mlarge'
            }
          };
          return {
            style: {
              font: 'alternate',
              fontWeight: 'bold',
              letterSpacing: '0.063em',
              textTransform: 'none',
              ...sizes[size]
            }
          };
        },
        InputAlpha: props => {
          return merge(mergeTypographyOverrides.call(this, 'BodyAlpha', props), {
            style: {
              color: 'input',
              ['@xs-only']: {
                fontSize: 'medium' // 16px
              }
            }
          });
        }
      }
    };
  }

  Section(props) {
    return super.Section(
      this.merge(
        {
          style: {
            'paddingVertical': 'xlarge',
            '@xs-only': {
              paddingVertical: 'large'
            }
          }
        },
        { ...props, skipDivider: true }
      )
    );
  }

  Heading(props) {
    const { tag, level } = props;
    const { widgetType, widgetPreset } = this.base;
    const typographyMapping = {
      2: 'HeadingEpsilon'
    };

    return super.Heading(
      this.merge(
        {
          typography: typographyMapping[levelFilter({ tag, level })],
          style: getMenuHeadingStyle(tag, widgetType, widgetPreset)
        },
        props
      )
    );
  }

  ContentBasic(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    return super.ContentBasic(this.merge(overrides, props));
  }

  ContentCard(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(overrides, styles, props));
  }

  NavLink(props) {
    return super.NavLink(
      this.merge(
        {
          style: {
            lineHeight: '1.5em'
          }
        },
        props
      )
    );
  }

  NavLinkActive(props) {
    const paddingOffset = this.parseSizedUnit(this.mapPropValue('padding', '-xxsmall'))[0];
    const marginBottom = paddingOffset - 1;
    return this.NavLink(
      this.merge(
        {
          active: true,
          style: {
            marginBottom
          }
        },
        props
      )
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...boldIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Cascade.apply(this, [props]);
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            backgroundColor: 'sectionContrast'
          }
        },
        props
      )
    );
  }

  Phone({ treatmentName, alignmentOption, ...props }) {
    const isInsetOrBlur = [INSET, BLUR].includes(treatmentName);
    return super.Phone(
      this.merge(
        {
          style: {
            'paddingTop': 'small',
            '@sm': {
              paddingHorizontal: 'medium',
              paddingTop: 0,
              display: 'inline-block'
            },
            '@md': {
              ...(isInsetOrBlur &&
                alignmentOption !== 'center' && {
                paddingTop: 'xlarge'
              })
            }
          }
        },
        props
      )
    );
  }

  Hero(props) {
    return super.Hero(
      this.merge(
        {
          style: {
            'textAlign': 'center',
            '@md': {
              maxWidth: '100%' // Overrides default maxWidth on Desktop
            }
          }
        },
        props
      )
    );
  }

  HeroLeft(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            '@sm': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
              borderLeftStyle: 'solid',
              borderLeftWidth: '1px',
              paddingLeft: 'large'
            },
            'border': 'none',
            'paddingLeft': 'none'
          }
        },
        props
      )
    );
  }

  HeroRight(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            '@sm': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              paddingRight: 'large'
            },
            'border': 'none',
            'paddingRight': 0
          }
        },
        props
      )
    );
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            '@xs-only': {
              maxWidth: '100%',
              marginBottom: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  HeroCenterHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderColor: 'rgba(255, 255, 255, 0.5)',
            paddingBottom: 'small'
          }
        },
        props
      )
    );
  }

  HeroText(props) {
    return super.Text(
      this.merge(
        {
          style: {
            lineHeight: '1.5'
          }
        },
        props
      )
    );
  }

  Table(props) {
    return super.Table(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  sharedInputStyles = {
    style: {
      borderColor: 'inputDisabled',
      borderWidth: 'xsmall',
      borderRadius: 'none',
      borderStyle: 'solid'
    }
  };

  Input(props) {
    return super.Input(
      this.merge(
        { ...this.sharedInputStyles },
        {
          style: {
            paddingVertical: 'small',
            paddingHorizontal: 'small'
          }
        },
        props
      )
    );
  }

  InputFloatLabelInput(props) {
    return super.InputFloatLabelInput(
      this.merge(
        {
          style: {
            paddingBottom: 'xsmall'
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            left: '16px'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          ...this.sharedInputStyles
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingVertical: 'small',
            paddingHorizontal: 'small'
          }
        },
        props
      )
    );
  }

  Button(props) {
    const customBorderWidth = 'small';
    const { renderMode } = this.base;
    const noHoverState = renderMode && renderMode === constants.renderModes.EDIT;
    const {
      fill = this.getButtonDefaults().fill,
      color = this.getButtonDefaults().color,
      shadow = this.getButtonDefaults().shadow
    } = props;
    const palette = {
      PRIMARY: {
        color: 'primary'
      },
      HIGHCONTRAST: {
        color: 'highContrast'
      }
    };
    const animation =
      fill !== buttons.fills.OPEN
        ? {
          style: {
            transition: 'all 0.3s',
            transitionProperty: 'background-color',
            transitionDuration: 'medium',
            transitionTimingFunction: 'ease-in-out'
          }
        }
        : {};
    const solidOverride =
      fill === buttons.fills.SOLID && shadow === buttons.shadows.NONE
        ? {
          style: {
            ...(!noHoverState && {
              [':hover']: {
                ['@md']: {
                  boxShadow: `inset 0 0 0 ${this.mapPropValue('borderWidth', customBorderWidth)}`,
                  color: palette[color].color,
                  backgroundColor: 'transparent'
                }
              }
            })
          }
        }
        : {};
    return super.Button(this.merge({ customBorderWidth }, solidOverride, animation, props));
  }

  SectionHeading(props) {
    const alignmentStyles =
      this.base.widgetPreset === 'payment2'
        ? { style: { textAlign: 'center' } }
        : { style: { textAlign: 'left' } };

    return super.SectionHeading(
      this.merge(
        {
          style: {
            '@xs-only': {
              marginBottom: 'medium'
            }
          },
          sectionHeadingHR: FULL_UNDERLINE
        },
        alignmentStyles,
        props
      )
    );
  }

  SectionSplitHeading(props) {
    return this.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'left'
          }
        },
        props
      )
    );
  }

  DividerHR(props) {
    return super.HR(
      this.merge(
        {
          style: {
            borderColor: '! #999'
          }
        },
        props
      )
    );
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(
      this.merge(
        {
          style: {
            borderRadius: '0'
          }
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(
      this.merge(
        {
          typography: 'HeadingEpsilon'
        },
        props
      )
    );
  }

  PromoBanner(props) {
    return super.PromoBanner(
      this.merge(
        {
          style: {
            position: 'relative',
            zIndex: 2
          }
        },
        props
      )
    );
  }
}

export default Theme17;
